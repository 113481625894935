<template>
  <div
    :class="{
      'cc-dropzone': !files.length && showDropzone,
      'cc-dropzone-min-height': !fit,
    }"
  >
    <div
      :class="{ 'dropzone-container': !files.length && showDropzone }"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        name="file"
        :id="'fileInput-' + dropzoneId"
        class="hidden-input"
        @change="onChange"
        ref="file"
        :accept="acceptFormats"
        :required="required"
        v-if="!files.length && showDropzone"
      />

      <label
        :for="'fileInput-' + dropzoneId"
        class="file-label"
        v-if="!files.length && showDropzone"
      >
        <h4 class="text-primary">{{ title }}</h4>
        <span>{{ subtitle }}</span>
      </label>

      <div class="preview-container" v-if="files.length || !showDropzone">
        <div class="d-flex justify-content-start align-items-center" v-if="hasValue || hasValueUrl">
          <b-badge variant="light-primary" class="m-1 w-fit">
            <feather-icon icon="PaperclipIcon" size="16" />
            {{ truncatedFileName || extractNameFromS3Url(urlValue) }}
          </b-badge>
          <feather-icon
            icon="DownloadIcon"
            size="20"
            stroke="#093272"
            class="cursor-pointer"
            @click="downloadURI(urlValue)"
            v-if="urlValue"
          />
          <feather-icon
            class="cursor-pointer ml-1"
            icon="Trash2Icon"
            size="16"
            @click="changeView()"
          />
        </div>
        <div
          v-else
          v-for="file in files"
          :key="file.name"
          class="d-flex flex-row justify-content-start align-items-center"
        >
          <b-badge variant="light-primary" class="mt-2">
            <feather-icon icon="PaperclipIcon" size="16" /> {{ truncatedFileName }}
          </b-badge>
          <feather-icon
            class="ml-1 mt-2"
            icon="Trash2Icon"
            size="16"
            @click="remove(files.indexOf(file))"
          />
        </div>
        <div v-if="hasFormatError">
          <small class="text-danger">
            O(s) arquivo(s) selecionados não são do formato permitido! ({{ acceptFormats }})
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import { extractNameFromS3Url, downloadURI } from '@core/comp-functions/forms/cc-dropzone'

export default {
  name: 'CCDropzone',
  components: {
    BBadge,
  },
  props: {
    title: {
      type: String,
      default: 'Arraste seu arquivo até aqui ou clique para procurar.',
    },
    subtitle: {
      type: String,
      default: 'Envie seu arquivo no formato XML.',
    },
    acceptFormats: {
      type: String,
      default: '.xml',
    },
    required: {
      type: Boolean,
      default: true,
    },
    convertToBase64: {
      type: Boolean,
      default: false,
    },
    fit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDragging: false,
      files: [],
      hasFormatError: false,
      hasValue: false,
      hasValueUrl: false,
      showDropzone: true,
      dropzoneId: '',
    }
  },
  async created() {
    this.dropzoneId = uuidv4()
    this.hasValue = this.checkInputValue
    await this.changeHasValue()
  },
  methods: {
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    async onChange() {
      try {
        this.files = [...this.$refs.file.files]
        let result = this.files[0]

        if (this.convertToBase64) result = await this.toBase64(this.files[0])

        await this.validateFileType(result)
      } catch (error) {
        console.log(error)
      }
    },
    dragover(e) {
      e.preventDefault()
      this.isDragging = true
    },
    dragleave() {
      this.isDragging = false
    },
    drop(e) {
      e.preventDefault()
      this.$refs.file.files = e.dataTransfer.files
      this.onChange()
      this.isDragging = false
    },
    remove(i) {
      this.files.splice(i, 1)
      this.$emit('input', '')
    },
    clear() {
      this.files.splice(0, this.files.length)
      this.$emit('input', '')
    },
    addFile(file) {
      this.files = [file]
      this.$emit('input', file)
    },
    validateFileType(file) {
      let formats = this.acceptFormats.split(', ')

      let fileExtension = file.name.split('.').pop()

      if (!formats.includes(`\.${fileExtension}`)) {
        this.hasFormatError = true
        this.$emit('input', '')
      } else {
        this.hasFormatError = false
        this.$emit('input', file)
      }
    },
    extractNameFromS3Url,
    downloadURI,
    changeView() {
      this.showDropzone = true
      this.hasValueUrl = false
      this.files.splice(0, 1)
      this.$emit('input', '')
    },
    changeHasValue() {
      if (this.$attrs.value.url) this.hasValueUrl = true
      if (this.$attrs.value.url) this.showDropzone = false
    },
  },
  computed: {
    truncatedFileName() {
      if (!this.files.length) return ''

      const name = this.files[0].name
      return name.length > 20 ? `${this.files[0].name.slice(0, 20)}...` : this.files[0].name
    },
    checkInputValue() {
      return this.$attrs.value !== null && this.$attrs.value !== ''
    },
    checkInputValueUrl() {
      return this.$attrs.value.url !== null && this.$attrs.value.url !== ''
    },
    urlValue() {
      return this.$attrs.value.url
    },
  },
}
</script>

<style lang="scss">
.cc-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.cc-dropzone-min-height {
  min-height: 150px;
}

.dropzone-container {
  padding: 20px;
  border: 2px dashed #dae1e8;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  display: block;
  cursor: pointer;
}

.preview-container {
}

.preview-card {
  display: flex;
  border: 1px solid #a2a2a2;
  padding: 5px;
  margin-left: 5px;
}

.preview-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
</style>
